<template>
  <b-modal
    id="modal-from-articulo"
    v-model="modalArticulo"
    ref="modal"
    :title="tituloModal"
    no-close-on-backdrop
    :ok-disabled="submitted"
    :cancel-disabled="submitted"
    no-close-on-esc
    ok-title="Guardar"
    ok-variant="primary"
    cancel-variant="outline-secondary"
    cancel-title="Cerrar"
    variant="dark"
    button-size="sm"
    size="xl"
    @cancel.prevent="cerrarModal"
    @close.prevent="cerrarModal"
    @ok.prevent="validar"
  >
    <b-tabs content-class="mt-2" justified>
      <b-tab active title="Informacion">
        <b-overlay
          :show="submitted"
          no-wrap
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        />
        <hr />
        <validation-observer ref="simpleRules">
          <b-form>
            <!-- fila 1 -->
            <b-row>
              <b-col cols="12" md="6" lg="6" class="mb-0-5">
                <b-form-group
                  label="* CODIGO"
                  label-for="codigo"
                  label-cols="12"
                  label-cols-lg="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Codigo"
                    :rules="ruleCodigo"
                  >
                    <b-input-group>
                      <b-form-input
                        id="codigo"
                        v-model="form.codigo"
                        :disabled="form.codigo_generado"
                        lazy-formatter
                        size="sm"
                        :formatter="textFormatter"
                        :state="errors.length > 0 ? false : null"
                      />
                      <b-input-group-append is-text>
                        <b-form-checkbox
                          name="checkbox-input"
                          v-model="form.codigo_generado"
                          :value="true"
                          plain
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    <small
                      v-for="error in errores.codigo"
                      :key="error"
                      class="text-danger"
                      >{{ error }}</small
                    >
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6" lg="12" class="mb-0-5">
                <b-form-group
                  label="* NOMBRE"
                  label-for="descripcion"
                  label-cols="12"
                  label-cols-lg="2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Descripcion"
                    rules="required|min:4"
                  >
                    <b-form-input
                      id="descripcion"
                      size="sm"
                      v-model="form.descripcion"
                      lazy-formatter
                      :formatter="textFormatter"
                      :state="errors.length > 0 ? false : null"
                    />
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    <small
                      v-for="error in errores.descripcion"
                      :key="error"
                      class="text-danger"
                      >{{ error }}</small
                    >
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- fila 2 para farmacia -->
            <b-row class="mb-0-5" v-if="mixing.giroComercialId == 1">
              <b-col cols="12" md="12" lg="8">
                <b-form-group
                  label="PRINCIPIO ACTIVO"
                  label-for="principio_activo"
                  label-cols="12"
                  label-cols-lg="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Principio Activo"
                    rules=""
                  >
                    <b-form-input
                      id="principio_activo"
                      lazy-formatter
                      :formatter="textFormatter"
                      :state="errors.length > 0 ? false : null"
                      v-model="form.principio_activo"
                      size="sm"
                    />
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    <small
                      v-for="error in errores.principio_activo"
                      :key="error"
                      class="text-danger"
                      >{{ error }}</small
                    >
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12" lg="4">
                <b-form-group
                  label="R.S."
                  label-for="registro_sanitario"
                  label-cols="12"
                  label-cols-lg="2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Registro Sanitario"
                  >
                    <b-form-input
                      id="registro_sanitario"
                      lazy-formatter
                      :formatter="textFormatter"
                      :state="errors.length > 0 ? false : null"
                      v-model="form.registro_sanitario"
                      size="sm"
                    />
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    <small
                      v-for="error in errores.registro_sanitario"
                      :key="error"
                      class="text-danger"
                      >{{ error }}</small
                    >
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- fila 3 -->
            <b-row class="mb-0-5">
              <b-col cols="12" md="12" lg="6">
                <validation-provider
                  #default="{ errors }"
                  name="Ubicacion"
                  rules="required"
                >
                  <b-form-group
                    label="* UBICACION123"
                    label-for="ubicacion"
                    label-cols="12"
                    label-cols-lg="4"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-row>
                      <b-col cols="9" md="10" style="padding-right: 0">
                        <v-select
                          id="ubicacion"
                          v-model="ubicacion"
                          class="style-chooser select-size-sm"
                          label="descripcion"
                          :options="ubicacionItems"
                          @input="obtenerUbicacion"
                          :class="{ 'is-invalid': !!errors.length }"
                        />
                      </b-col>
                      <b-col cols="3" md="2" style="padding-left: 0">
                        <b-dropdown block variant="outline-secondary" size="sm">
                          <template #button-content>
                            <feather-icon icon="Edit2Icon" />
                          </template>
                          <b-dropdown-item
                            @click="
                              $refs.modalFormUbicacionRef.abrir('registrar', [])
                            "
                          >
                            Nuevo
                          </b-dropdown-item>
                          <b-dropdown-item
                            :disabled="
                              !form.ubicacion_id || form.ubicacion_id == 1
                            "
                            @click="
                              $refs.modalFormUbicacionRef.abrir(
                                'actualizar',
                                ubicacion
                              )
                            "
                          >
                            Editar
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-col>
                    </b-row>
                    <small
                      v-for="error in errores.ubicacion_id"
                      :key="error"
                      class="text-danger"
                      >{{ error }}</small
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="12" lg="6">
                <validation-provider
                  #default="{ errors }"
                  name="Unidad"
                  rules="required"
                >
                  <b-form-group
                    label="* UNIDAD"
                    label-for="unidad"
                    label-cols="12"
                    label-cols-lg="4"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="unidad"
                      v-model="form.unidad_id"
                      class="style-chooser select-size-sm"
                      label="descripcion"
                      :options="unidadItems"
                      :key="form.unidad_id"
                      :reduce="(op) => op.id"
                      @input="obtenerUnidad"
                      :class="{ 'is-invalid': !!errors.length }"
                    />
                    <small
                      v-for="error in errores.unidad_id"
                      :key="error"
                      class="text-danger"
                      >{{ error }}</small
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- fila 4 -->
            <b-row>
              <b-col cols="12" md="6" lg="6" class="mb-0-5">
                <validation-provider
                  #default="{ errors }"
                  name="Categoria"
                  rules="required"
                >
                  <b-form-group
                    label="* CATEGORIA"
                    label-for="categoria"
                    label-cols="12"
                    label-cols-lg="4"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-row>
                      <b-col cols="9" md="10" style="padding-right: 0">
                        <v-select
                          id="categoria"
                          v-model="categoria"
                          class="style-chooser select-size-sm"
                          label="descripcion"
                          :options="categoriaItems"
                          @input="obtenerCategoria"
                          :class="{ 'is-invalid': !!errors.length }"
                        >
                          <template v-slot:option="option">
                            <span v-html="option.descripcion1"></span>
                          </template>
                        </v-select>
                      </b-col>
                      <b-col cols="3" md="2" style="padding-left: 0">
                        <b-dropdown block variant="outline-secondary" size="sm">
                          <template #button-content>
                            <feather-icon icon="Edit2Icon" />
                          </template>
                          <b-dropdown-item
                            @click="$refs.modalFormRef.abrir('registrar', [])"
                          >
                            Nuevo
                          </b-dropdown-item>
                          <b-dropdown-item
                            :disabled="
                              !form.categoria_id || form.categoria_id == 1
                            "
                            @click="
                              $refs.modalFormRef.abrir('actualizar', categoria)
                            "
                          >
                            Editar
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-col>
                    </b-row>
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    <small
                      v-for="error in errores.categoria_id"
                      :key="error"
                      class="text-danger"
                      >{{ error }}</small
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6" class="mb-0-5">
                <validation-provider
                  #default="{ errors }"
                  name="Marca (Linea)"
                  rules="required"
                >
                  <b-form-group
                    label="* MARCA (LINEA)"
                    label-for="marca"
                    label-cols="12"
                    label-cols-lg="4"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-row>
                      <b-col cols="9" md="10" style="padding-right: 0">
                        <v-select
                          id="marca"
                          v-model="marca"
                          class="style-chooser select-size-sm"
                          label="descripcion"
                          :options="marcaItems"
                          @input="obtenerMarca"
                          :class="{ 'is-invalid': !!errors.length }"
                        />
                      </b-col>
                      <b-col cols="3" md="2" style="padding-left: 0">
                        <b-dropdown block variant="outline-secondary" size="sm">
                          <template #button-content>
                            <feather-icon icon="Edit2Icon" />
                          </template>
                          <b-dropdown-item
                            @click="
                              $refs.modalFormMarcaRef.abrir('registrar', [])
                            "
                          >
                            Nuevo
                          </b-dropdown-item>
                          <b-dropdown-item
                            :disabled="!form.marca_id || form.marca_id == 1"
                            @click="
                              $refs.modalFormMarcaRef.abrir('actualizar', marca)
                            "
                          >
                            Editar
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-col>
                    </b-row>
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    <small
                      v-for="error in errores.marca_id"
                      :key="error"
                      class="text-danger"
                      >{{ error }}</small
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row class="mb-0-5" v-if="mixing.realizarFacturacion">
              <b-col cols="12" md="12" lg="12">
                <validation-provider
                  #default="{ errors }"
                  name="actividad"
                  rules=""
                >
                  <b-form-group
                    label="* ACTIVIDAD"
                    label-for="actividad"
                    label-cols="12"
                    label-cols-lg="2"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="unidad"
                      v-model="form.codigo_actividad"
                      class="style-chooser select-size-sm"
                      label="descripcion"
                      :options="actividadItems"
                      :key="form.codigo_actividad"
                      :reduce="(op) => op.codigo"
                      @input="obtenerActividad"
                      :class="{ 'is-invalid': !!errors.length }"
                    />
                    <small
                      v-for="error in errores.codigo_actividad"
                      :key="error"
                      class="text-danger"
                      >{{ error }}</small
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row class="mb-0-5" v-if="mixing.realizarFacturacion">
              <b-col cols="12" md="12" lg="12">
                <validation-provider
                  #default="{ errors }"
                  name="producto servicio"
                  rules=""
                >
                  <b-form-group
                    label="* PRODUCTO SERVICO"
                    label-for="producto_servicio"
                    label-cols="12"
                    label-cols-lg="2"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="producto_servicio"
                      v-model="form.codigo_sin"
                      class="style-chooser select-size-sm"
                      label="text"
                      :options="productoServicioItems"
                      :key="form.codigo_sin"
                      :reduce="(op) => op.codigoProductoServicio"
                      :class="{ 'is-invalid': !!errors.length }"
                    />
                    <small
                      v-for="error in errores.codigo_sin"
                      :key="error"
                      class="text-danger"
                      >{{ error }}</small
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row class="mb-0-5">
              <b-col cols="12" md="6" lg="6">
                <b-form-group
                  label="* PRECIO UNITARIO"
                  label-for="precio_unitario"
                  label-cols="12"
                  label-cols-lg="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Precio Unitario"
                    rules="required|positive"
                  >
                    <b-input-group prepend="Bs. " class="input-group-merge">
                      <b-form-input
                        id="precio_unitario"
                        type="number"
                        step="any"
                        lazy-formatter
                        :formatter="numberFormatter"
                        :state="errors.length > 0 ? false : null"
                        onClick="this.select();"
                        v-model="form.precio_unitario"
                        size="sm"
                      />
                      <b-input-group-append
                        is-text
                        v-if="$hascan('producto-precios_todas_sedes')"
                      >
                        <b-form-checkbox
                          id="precio_para_todos"
                          name="checkbox-input"
                          v-model="form.precio_para_todos"
                          plain
                        >
                        </b-form-checkbox>

                        <label
                          style="margin-left: 0.5rem"
                          for="precio_para_todos"
                        >
                          Precio para Todas las Sedes
                        </label>
                      </b-input-group-append>
                    </b-input-group>
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    <small
                      v-for="error in errores.precio_unitario"
                      :key="error"
                      class="text-danger"
                      >{{ error }}</small
                    >
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mb-0-5">
              <b-col cols="12" md="12" lg="6">
                <b-form-group
                  label="* EXISTENCIA MINIMA"
                  label-for="existencia_minima"
                  label-cols="12"
                  label-cols-lg="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Existencia Minima"
                    rules="required"
                  >
                    <b-form-input
                      id="existencia_minima"
                      type="number"
                      lazy-formatter
                      onClick="this.select();"
                      :formatter="integerFormatter"
                      :state="errors.length > 0 ? false : null"
                      v-model="form.existencia_minima"
                      size="sm"
                    />
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    <small
                      v-for="error in errores.existencia_minima"
                      :key="error"
                      class="text-danger"
                      >{{ error }}</small
                    >
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mb-0-5">
              <b-col cols="12" md="6" lg="12">
                <b-form-group
                  label="DESCRIPCIÓN CORTA"
                  label-for="descripcion_corta"
                  label-cols="12"
                  label-cols-lg="2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Descripción"
                    rules="max:1000"
                  >
                    <b-form-textarea
                      id="descripcion_corta"
                      size="sm"
                      v-model="form.descripcion_corta"
                      lazy-formatter
                      :formatter="textFormatter"
                      :state="errors.length > 0 ? false : null"
                      rows="3"
                    />
                    <small
                      v-for="error in errores.descripcion_corta"
                      :key="error"
                      class="text-danger"
                      >{{ error }}</small
                    >
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mb-0-5">
              <b-col cols="12" md="6" lg="12">
                <b-form-group
                  label="DESCRIPCIÓN LARGA"
                  label-for="descripcion_larga"
                  label-cols="12"
                  label-cols-lg="2"
                >
                  <quill-editor
                    v-model="form.descripcion_larga"
                    :options="options"
                  >
                  </quill-editor>
                </b-form-group>
              </b-col>
            </b-row>

            <hr />
            <b-row>
              <b-col cols="12" md="12" lg="12" class="mb-1">
                <b-row>
                  <b-col cols="2" class="mb-1">
                    <b-form-checkbox v-model="form.esta_activo" :value="1">
                      ESTA ACTIVO
                    </b-form-checkbox>
                  </b-col>
                  <b-col v-if="!form.id" cols="2" class="mb-1">
                    <b-form-checkbox v-model="form.es_servicio" :value="1">
                      ES SERVICIO
                    </b-form-checkbox>
                  </b-col>
                  <b-col cols="2" class="mb-1">
                    <b-form-checkbox v-model="form.novedoso" :value="1">
                      NOVEDOSO
                    </b-form-checkbox>
                  </b-col>
                  <!-- para farmacia -->
                  <b-col
                    cols="2"
                    class="mb-1"
                    v-if="mixing.giroComercialId == 1"
                  >
                    <b-form-checkbox v-model="form.venta_controlada" :value="1">
                      VENTA CONTROLADA
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <ModalFormCategoria
          v-if="item"
          ref="modalFormRef"
          :item="item"
          @actualizarListado="actualizarCategoria"
        />
        <ModalFormUbicacion
          ref="modalFormUbicacionRef"
          :item="item"
          @actualizarUbicacion="actualizarUbicacion"
        />
        <ModalFormMarca
          ref="modalFormMarcaRef"
          :item="item"
          @actualizarListado="actualizarMarca"
        />
      </b-tab>

      <b-tab title="Imagenes">
        <hr />
        <b-row style="display: flex; justify-content: center">
          <b-col cols="12" md="12" lg="4">
            <div class="boxs boxs1 mb-1">
              <b-img
                v-if="vistaPreviaImagen"
                :src="vistaPreviaImagen"
                center
                thumbnail
                fluid
                class="rounded"
                alt="Sin imagen"
              />
              <b-img
                v-else
                v-bind="imgAtributos"
                rounded
                alt="Rounded image"
                class="d-inline-block mr-1 mb-1 border"
              />
            </div>
            <b-form-group
              label="Imagen de Portada (400 x 300)"
              label-for="imagen"
            >
              <validation-provider #default="{ errors }" name="imagen" rules="">
                <b-form-file
                  id="wildcard"
                  v-model="form.imagen"
                  accept="image/*"
                  placeholder="Elija una imagen"
                  drop-placeholder="Ningun imagen seleccionado"
                  @change="onFileChanged"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.imagen"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="12" md="12">
            <b-form-group label="Imagenes (400 x 300)" label-for="imagen">
              <file-pond
                ref="pond"
                name="files"
                label-idle="<p style='color: black !important'>Agrega las Imagenes <span class='filepond--label-action'>Browse</span></p>"
                allow-multiple="true"
                accepted-file-types="image/*"
                v-bind:files="files"
                :max-files="5"
                allowReorder="true"
                data-max-file-size="3MB"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BModal,
  BButton,
  VBModal,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BOverlay,
  BTabs,
  BTab,
  BFormSelect,
  BInputGroup,
  BFormFile,
  BInputGroupAppend,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BImg,
  BFormCheckbox,
  BButtonGroup,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import vSelect from "vue-select";
import ModalFormCategoria from "@/modules/administracion/categoria/components/ModalFormCategoria.vue";
import ModalFormUbicacion from "../../ubicacion/components/ModalFormUbicacion.vue";
import ModalFormMarca from "../../marca/components/ModalFormMarca.vue";
import ProductoServices from "../services/index";
import FacturacionServices from "@/modules/configuracion-sin/services/index";
import UnidadServices from "@/modules/configuracion-sin/services/unidad";
import formImagenMixin from "../../mixins/form-imagen-mixin";

// Filepond
import vueFilePond from "vue-filepond";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BTabs,
    BTab,
    BFormSelect,
    vSelect,
    BInputGroup,
    BFormFile,
    BOverlay,
    BInputGroupAppend,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    ModalFormCategoria,
    BImg,
    BFormCheckbox,
    BButtonGroup,
    ModalFormUbicacion,
    ModalFormMarca,
    BFormTextarea,
    FilePond,
    quillEditor,
  },
  mixins: [formImagenMixin],
  props: ["item"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      options: {},
      form: {
        id: null,
        descripcion: "",
        codigo: "",
        codigo_actividad: null,
        precio_unitario: "0.00",
        categoria_id: null,
        marca_id: null,
        ubicacion_id: null,
        unidad_id: null,
        codigo_sin: null,
        unidad: "",
        imagen: null,
        estado: 1,
        // variaciones: [],
        // adicionales: [],
        codigo_generado: true,
        es_servicio: false,
        esta_activo: true,
        novedoso: false,
        descripcion_corta: "",
        descripcion_larga: "",
        sede_id: this.mixing?.sede_id,
        precio_para_todos: false,

        // para farmacia
        principio_activo: "",
        registro_sanitario: "",
        venta_controlada: 0,
        existencia_minima: 0, // traer de configuracion
      },
      files: [],
      categoria: [],
      marca: [],
      ubicacion: [],
      required,
      submitted: false,
      errores: [],
      categoriaItems: [],
      marcaItems: [],
      ubicacionItems: [],
      unidad: null,
      unidadItems: [],
      catalogoItems: [],
      actividadItems: [],
      productoServicioItems: [],
      catalogo: null,
      modalArticulo: false,
      estadoItems: [
        { text: "ACTIVO", value: 1 },
        { text: "INACTIVO", value: 0 },
      ],

      variacionesItems: [],
      adicionalesItems: [],
    };
  },
  created: function () {
    window.addEventListener("keyup", this.onkey);
  },
  destroyed: function () {
    window.removeEventListener("keyup", this.onkey);
  },
  computed: {
    tituloModal() {
      return this.form.id ? "EDITAR ARTICULO" : "NUEVO ARTICULO";
    },
    ruleCodigo() {
      return this.form.codigo_generado ? "" : "required|min:2";
    },
  },
  watch: {
    "form.descripcion": function () {
      this.errores.descripcion = [];
    },
    "form.categoria_id": function () {
      this.errores.categoria_id = [];
    },
    "form.unidad_id": function () {
      this.errores.unidad_id = [];
    },
    "form.codigo": function () {
      this.errores.codigo = [];
    },
    "form.codigo_generado": function (value) {
      if (value && !this.form.id) {
        this.form.codigo = "";
        document.getElementById("descripcion").focus();
      } else {
        document.getElementById("codigo").focus();
      }
    },
    "form.descripcion_corta": function () {
      this.errores.descripcion_corta = [];
    },
    "form.precio_para_todos": function (value) {
      if (value) {
        this.$bvModal
          .msgBoxConfirm(
            "¿Está seguro que desea aplicar el precio para todas las sedes?",
            {
              title: "Confirmar",
              size: "sm",
              buttonSize: "sm",
              okVariant: "primary",
              okTitle: "Si, para todas las sedes",
              cancelTitle: "No, solo para esta sede",
              cancelVariant: "outline-dark",
              hideHeaderClose: false,
            }
          )
          .then((value) => {
            this.form.precio_para_todos = value;
          });
      }
    },

    // para farmacia
    "form.principio_activo": function () {
      this.errores.principio_activo = [];
    },
    "form.registro_sanitario": function () {
      this.errores.registro_sanitario = [];
    },
    "form.venta_controlada": function () {
      this.errores.venta_controlada = [];
    },
    "form.existencia_minima": function () {
      this.errores.existencia_minima = [];
    },
  },
  methods: {
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            if (!this.form.es_servicio) {
              this.form.es_servicio = 0;
            }
            if (!this.form.esta_activo) {
              this.form.esta_activo = 0;
            }
            this.submitted = true;
            if (this.form.id) {
              this.actualizar();
            } else {
              this.registrar();
            }
          }
        }
      });
    },
    abrir(accion, data = []) {
      switch (accion) {
        case "registrar": {
          this.form.id = null;
          this.form.descripcion = "";
          this.form.codigo = "";
          this.form.precio_unitario = "0.00";
          this.form.categoria_id = 1;
          this.form.marca_id = 1;
          this.form.ubicacion_id = 1;
          this.form.unidad_id = null;
          this.form.codigo_sin = null;
          this.form.unidad = "";
          this.form.imagen = null;
          this.files = [];
          this.form.estado = 1;
          this.modalArticulo = true;
          this.form.codigo_actividad = "";

          this.categoria = {
            descripcion: "GENERAL",
            id: 1,
          };
          this.marca = {
            descripcion: "GENERAL",
            id: 1,
          };
          this.ubicacion = {
            descripcion: "GENERAL",
            id: 1,
          };
          this.vistaPreviaImagen = "";
          this.form.codigo_generado = true;

          // this.ruleCodigo = "required|min:2";

          this.form.es_servicio = 0;
          this.form.esta_activo = 1;
          this.form.novedoso = false;

          this.productoServicioItems = [];
          this.form.descripcion_corta = "";
          this.form.descripcion_larga = "";

          this.form.sede_id = this.mixing.sede_id;

          this.form.precio_para_todos = false;

          // para farmacia
          this.form.principio_activo = "";
          this.form.registro_sanitario = "";
          this.form.venta_controlada = 0;
          this.form.existencia_minima = 0;

          break;
        }
        case "actualizar": {
          this.form.id = data["id"];
          this.form.descripcion = data["descripcion"];
          this.form.codigo = data["codigo"];
          this.form.precio_unitario = data["precio_unitario"];
          this.form.categoria_id = data["categoria_id"];
          this.form.marca_id = data["marca_id"];
          this.form.ubicacion_id = data["ubicacion_id"];
          this.form.unidad_id = data["unidad_id"];
          this.form.codigo_sin = data["codigo_sin"];
          this.form.unidad = data["unidad"];
          this.form.imagen = null;
          this.form.estado = data["estado"];
          this.categoria = data["categoria"];
          this.ubicacion = data["ubicacion"];
          this.marca = data["marca"];
          this.modalArticulo = true;
          this.vistaPreviaImagen =
            data["imagen"] == "null" ? "" : data["imagen"];
          this.files = data.img_multi;
          this.form.codigo_generado = true;

          this.form.es_servicio = data["es_servicio"];
          this.form.esta_activo = data["esta_activo"];
          this.form.novedoso = data.novedoso;
          this.form.codigo_actividad = data["codigo_actividad"];
          this.obtenerActividad(this.form.codigo_actividad);
          this.form.codigo_sin = data["codigo_sin"];
          this.form.descripcion_corta =
            data["descripcion_corta"] == "null"
              ? ""
              : data["descripcion_corta"];
          this.form.descripcion_larga =
            data["descripcion_larga"] == "null"
              ? ""
              : data["descripcion_larga"];
          this.form.sede_id = this.mixing.sede_id;
          this.form.producto_sede_id = data["producto_sede_id"]
            ? data["producto_sede_id"]
            : null;

          this.form.precio_para_todos = false;

          // para farmacia
          this.form.principio_activo =
            data["principio_activo"] == "null" ? "" : data["principio_activo"];
          this.form.registro_sanitario =
            data["registro_sanitario"] == "null"
              ? ""
              : data["registro_sanitario"];
          this.form.venta_controlada = data["venta_controlada"];
          this.form.existencia_minima = data["existencia_minima"];

          break;
        }
      }
      this.getUnidades();
      this.getCategoriasSubcategorias();
      this.getMarcas();
      this.getUbicaciones();
      this.errores = [];
      setTimeout(() => {
        this.$nextTick(() => {
          document.getElementById("descripcion").focus();
        });
      }, 100);
    },
    cerrarModal() {
      this.form.id = null;
      this.modalArticulo = false;
    },
    actualizarArticulo(item) {
      this.form.id = null;
      this.modalArticulo = false;
      this.$emit("actualizarArticulo", item);
      this.submitted = false;
    },
    generarFormData() {
      const formData = new FormData();
      formData.append("descripcion", this.form.descripcion);
      formData.append("codigo", this.form.codigo);
      formData.append("precio_unitario", this.form.precio_unitario);
      formData.append("categoria_id", this.form.categoria_id);
      formData.append("unidad_id", this.form.unidad_id);
      formData.append("codigo_actividad", this.form.codigo_actividad);
      formData.append("codigo_generado", this.form.codigo_generado);
      formData.append("marca_id", this.form.marca_id);
      formData.append("ubicacion_id", this.form.ubicacion_id);
      formData.append("es_servicio", this.form.es_servicio);
      formData.append("esta_activo", this.form.esta_activo);
      formData.append("novedoso", this.form.novedoso);
      formData.append("descripcion_corta", this.form.descripcion_corta);
      formData.append("descripcion_larga", this.form.descripcion_larga);
      formData.append("sede_id", this.form.sede_id);

      if (this.form.producto_sede_id) {
        formData.append("producto_sede_id", this.form.producto_sede_id);
      }

      if (this.form.codigo_sin) {
        formData.append("codigo_sin", this.form.codigo_sin);
      }

      if (this.form.imagen) {
        formData.append("file", this.form.imagen);
      }

      // modificar
      const filePondInstance = this.$refs.pond;
      if (filePondInstance) {
        const files = filePondInstance.getFiles();
        if (files.length) {
          files.forEach((file) => {
            const aux = new File([file.file], file.file.name, {
              type: file.file.type,
            });
            formData.append("files", aux);
          });
        }
      }

      if (this.form.precio_para_todos) {
        formData.append("precio_para_todos", 1);
      } else {
        formData.append("precio_para_todos", 0);
      }

      // para farmacia
      formData.append("principio_activo", this.form.principio_activo);
      formData.append("registro_sanitario", this.form.registro_sanitario);
      formData.append("venta_controlada", this.form.venta_controlada);
      formData.append("existencia_minima", this.form.existencia_minima);

      return formData;
    },
    // SERVICES
    async registrar() {
      const formData = this.generarFormData();
      try {
        const response = await ProductoServices.store(formData);
        this.actualizarArticulo(response.data);
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async actualizar() {
      const formData = this.generarFormData();
      try {
        const response = await ProductoServices.update(this.form.id, formData);
        this.actualizarArticulo(response.data);
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    textFormatter(value) {
      return value.toUpperCase();
    },
    numberFormatter(value) {
      return (value * 1).toFixed(2);
    },
    integerFormatter(value) {
      return (value * 1).toFixed(0);
    },
    // Emit modal categoria
    actualizarCategoria(item) {
      this.categoria = item;
      this.obtenerCategoria();
      this.getCategoriasSubcategorias();
    },
    // Emit modal marca
    actualizarMarca(item) {
      this.marca = item;
      this.obtenerMarca();
      this.getMarcas();
    },
    // Emit modal ubicacion
    actualizarUbicacion(item) {
      this.ubicacion = item;
      this.obtenerUbicacion();
      this.getUbicaciones();
    },
    // Emit modal Adicional
    async actualizarAdicional(id) {
      await this.getAdicionales();
      this.form.adicionales.push(id);
    },
    // Emit modal Variacion
    async actualizarVariacion(id) {
      await this.getVariaciones();
      this.form.variaciones.push(id);
    },
    // unidades
    async getUnidades() {
      try {
        const response = await UnidadServices.get({ estado: 1 });
        this.unidadItems = response.data;
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    // CATALOGOS FACTURACION
    // actividades
    async getActividades() {
      try {
        const response = await FacturacionServices.getCatalogos(21);
        this.actividadItems = response.data.data.map((g) => ({
          descripcion: g.codigoCaeb + " - " + g.descripcion,
          codigo: g.codigoCaeb,
        }));
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    // productos servicios
    async getProductosServicios() {
      try {
        const response = await FacturacionServices.getCatalogos(22);
        this.catalogoItems = response.data.data.map((g) => ({
          text: g.codigoSin + " - " + g.descripcion,
          codigoProductoServicio: g.codigoSin,
          codigoCaeb: g.codigoCaeb,
          descripcion: g.descripcion,
        }));
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    // FIN CATALOGOS FACTURACION
    aplanar(categorias, categoriasAplanadas, nivel) {
      nivel += "&nbsp;&nbsp;&nbsp;&nbsp;";
      categorias.forEach((categoria) => {
        categoriasAplanadas.push({
          ...categoria,
          descripcion1: `${nivel}${categoria.descripcion}`,
        });
        this.aplanar(categoria.categorias, categoriasAplanadas, nivel);
      });
    },
    async getCategoriasSubcategorias() {
      try {
        const response = await ProductoServices.getCategoriasSubcategorias();
        const categorias = response.data;

        const categoriasAplanadas = [];

        this.aplanar(categorias, categoriasAplanadas, "");

        this.categoriaItems = categoriasAplanadas;
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async getMarcas() {
      try {
        const response = await ProductoServices.getMarcas();
        this.marcaItems = response.data;
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async getUbicaciones() {
      try {
        const response = await ProductoServices.getUbicaciones();
        this.ubicacionItems = response.data;
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    obtenerUnidad(value) {
      if (value) {
        this.form.unidad = this.unidadItems.find(
          (x) => x.id === value
        ).descripcion;
      } else {
        this.form.unidad = "";
      }
    },
    obtenerCategoria() {
      if (this.categoria == null) {
        this.form.categoria_id = null;
        this.categoria = [];
      } else {
        this.form.categoria_id = this.categoria.id;
      }
    },
    obtenerMarca() {
      if (this.marca == null) {
        this.form.marca_id = null;
        this.marca = [];
      } else {
        this.form.marca_id = this.marca.id;
      }
    },
    obtenerUbicacion() {
      if (this.ubicacion == null) {
        this.form.ubicacion_id = null;
        this.ubicacion = [];
      } else {
        this.form.ubicacion_id = this.ubicacion.id;
      }
    },
    obtenerActividad(value) {
      if (value) {
        let productoServicios = this.catalogoItems.filter((g) =>
          g.codigoCaeb.includes(parseFloat(value))
        );
        this.productoServicioItems = productoServicios;
        this.form.codigo_sin = null;
      } else {
        this.productoServicioItems = [];
        this.form.codigo_sin = null;
      }
    },
  },
  mounted() {
    this.options = {
      modules: {
        toolbar: [
          ["bold", "italic"],
          [{ size: ["small", false, "large", "huge"] }],
          [{ font: [] }],
        ],
      },
      theme: "snow",
    };
    if (this.mixing.realizarFacturacion) {
      this.getProductosServicios();
      this.getActividades();
    }
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.vs__deselect {
  fill: rgb(255 255 255);
}
.boxs {
  height: 14rem;
  background: #fff;
}
.boxs img {
  width: 100%;
  height: 100%;
}
.boxs img {
  object-fit: contain;
}
.filepond--item {
  width: calc(33% - 0.5em);
  height: 10rem !important;
}
.filepond--panel .filepond--item-panel {
  transform: none !important;
}
.filepond--image-preview-wrapper {
  object-fit: contain;
}
.filepond--file {
  color: white;
}

#modal-from-articulo {
  .modal-xl {
    margin-left: 20%;
    margin-right: 20%;
  }
}
</style>
