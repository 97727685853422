export default {
  data() {
    return {
      vistaPreviaImagen: null,
      imgAtributos: {
        blank: true,
        width: 75,
        height: 75,
        class: "m1",
      },
    };
  },
  methods: {
    onFileChanged(event) {
      if (event.target.files[0]) {
        const file = event.target.files[0];
        if (file == null) {
          this.form.imagen = null;
          this.vistaPreviaImagen = null;
        } else {
          this.vistaPreviaImagen = URL.createObjectURL(file);
          this.form.imagen = file;
        }
      } else {
        this.form.imagen = null;
        this.vistaPreviaImagen = null;
      }
    },
  },
};
