<template>
  <b-modal
    id="modal-from-marca"
    v-model="modalMarca"
    ref="modal"
    :title="tituloModal"
    no-close-on-backdrop
    :ok-disabled="submitted"
    :cancel-disabled="submitted"
    no-close-on-esc
    ok-title="Guardar"
    ok-variant="primary"
    cancel-variant="outline-secondary"
    cancel-title="Cerrar"
    button-size="sm"
    @cancel.prevent="cerrarModal"
    @close.prevent="cerrarModal"
    @ok.prevent="validar"
  >
    <b-overlay
      :show="submitted"
      no-wrap
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    />
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label-cols="12"
              label-cols-lg="3"
              label="* DESCRIPCION"
              label-for="descripcionCategoria"
            >
              <validation-provider
                #default="{ errors }"
                name="Descripcion"
                rules="required|min:2"
              >
                <b-form-input
                  id="descripcionMarca"
                  v-model="form.descripcion"
                  @keyup.enter="validar()"
                  lazy-formatter
                  :formatter="textFormatter"
                  :state="errors.length > 0 ? false : null"
                  size="sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.descripcion"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col class="mb-0-5 mt-2" cols="12" md="6" offset-md="3">
            <div class="contenedor-imagen-categoria-marca boxs1">
              <b-img
                v-if="vistaPreviaImagen"
                :src="vistaPreviaImagen"
                center
                thumbnail
                fluid
                class="rounded"
                alt="Sin imagen"
              />
              <b-img
                v-else
                v-bind="imgAtributos"
                rounded
                alt="Rounded image"
                class="d-inline-block mr-1 mb-1 border"
              />
            </div>

            <b-form-group class="mt-1" label="IMAGEN" label-for="imagen">
              <validation-provider #default="{ errors }" name="imagen" rules="">
                <b-form-file
                  @change="onFileChanged"
                  v-model="form.imagen"
                  accept="image/*"
                  placeholder="Elija una imagen"
                  drop-placeholder="Ningun imagen seleccionado"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-for="error in errores.imagen"
                  :key="error"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import MarcaServices from "../services/index";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BModal,
  BButton,
  VBModal,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BOverlay,
  BFormSelect,
  BImg,
  BFormFile,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import formImagenMixin from "../../mixins/form-imagen-mixin";

export default {
  name: "ModalFormMarca",
  components: {
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BOverlay,
    BFormSelect,
    BImg,
    BFormFile,
  },
  mixins: [formImagenMixin],
  props: ["item"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      form: {
        id: null,
        descripcion: "",
        estado: 1,
        imagen: null,
      },
      required,
      submitted: false,
      errores: [],
      modalMarca: false,
    };
  },
  computed: {
    tituloModal() {
      return this.form.id ? "EDITAR MARCA" : "NUEVA MARCA";
    },
  },
  watch: {
    "form.descripcion": function () {
      this.errores.descripcion = [];
    },
    "form.imagen": function () {
      this.errores.imagen = [];
    },
  },
  methods: {
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            this.submitted = true;
            if (this.form.id) {
              this.actualizar();
            } else {
              this.registrar();
            }
          }
        }
      });
    },
    async abrir(accion, data = []) {
      switch (accion) {
        case "registrar": {
          this.form.id = null;
          this.form.descripcion = "";
          this.form.estado = 1;
          this.form.imagen = null;
          this.vistaPreviaImagen = null;
          break;
        }
        case "actualizar": {
          await this.getMarca(data["id"]);
          // this.form.id = data["id"];
          // this.form.descripcion = data["descripcion"];
          // this.form.estado = data["estado"];
          // this.form.imagen = null;
          // this.vistaPreviaImagen = data["imagen"] ? data["imagen"] : null;
          break;
        }
      }
      this.modalMarca = true;

      setTimeout(() => {
        this.$nextTick(() => {
          document.getElementById("descripcionMarca").focus();
        });
      }, 100);

      this.errores = [];
    },
    async getMarca(id) {
      await MarcaServices.getOne(id)
        .then((response) => {
          const data = response.data;
          this.form.id = data.id;
          this.form.descripcion = data.descripcion;
          this.form.imagen = null;
          this.vistaPreviaImagen = data.imagen ? data.imagen : null;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cerrarModal() {
      this.form.id = null;
      this.modalMarca = false;
    },
    actualizarListado(item) {
      this.form.id = null;
      this.modalMarca = false;
      this.$emit("actualizarListado", item);
      this.submitted = false;
    },
    // SERVICES
    async registrar() {
      const formData = this.generarFormData();
      try {
        const response = await MarcaServices.store(formData);

        this.actualizarListado(response.data);
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async actualizar() {
      const formData = this.generarFormData();
      try {
        const response = await MarcaServices.update(this.form.id, formData);

        this.actualizarListado(response.data);
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    textFormatter(value) {
      return value.toUpperCase();
    },
    numberFormatter(value) {
      return (value * 1).toFixed(2);
    },
    generarFormData() {
      const formData = new FormData();
      formData.append("descripcion", this.form.descripcion);

      if (this.form.imagen) {
        formData.append("file", this.form.imagen);
      }

      return formData;
    },
  },
};
</script>

<style lang="scss">
.contenedor-imagen-categoria-marca {
  height: 14rem;
  background: #fff;
}

.contenedor-imagen-categoria-marca img {
  width: 100%;
  height: 100%;
  // object-fit: contain;
}
.boxs1 img {
  object-fit: contain;
}
</style>