<template>
  <div>
    <b-card title="Nuevo Inventario Inicial">
      <validation-observer ref="simpleRules">
        <b-form>
          <hr class="hr" />
          <b-row class="mb-0-5">
            <b-col cols="12" lg="8">
              <b-row>
                <b-col cols="6" lg="6">
                  <b-form-group
                    label="Código (Esc):"
                    label-for="codigo"
                    label-cols="12"
                    label-cols-lg="4"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="MaximizeIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="codigo"
                        v-model="codigo"
                        @keyup.enter="buscarCodigo()"
                        onClick="this.select();"
                        size="sm"
                        placeholder="Digite o Escanee el código"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-button
                    class="btnBuscarArticulo"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="dark"
                    size="sm"
                    block
                    @click="$refs.ModalSelectArticuloRef.focusBuscarArticulo()"
                  >
                    BUSCAR ARTICULO (F9)
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="mb-1">
            <b-col cols="12">
              <ag-grid-vue
                :gridOptions="gridOptions"
                class="ag-theme-balham ag-grid-tableInventarioInicial"
                :columnDefs="columnDefs"
                :defaultColDef="defaultColDef"
                @grid-ready="onGridReady"
                :rowData="transaccion.detalleTransaccion"
                :suppressRowClickSelection="true"
                colResizeDefault="shift"
                :animateRows="false"
                :floatingFilter="false"
                :rowClassRules="rowClassRules"
                @rowClicked="onRowClicked($event)"
                :alwaysShowVerticalScroll="true"
                @first-data-rendered="onFirstDataRendered"
                :getRowStyle="getRowStyle"
                :pinnedBottomRowData="pinnedBottomRowData"
                @cell-value-changed="onCellValueChanged"
                @cell-key-down="onCellKeyDown"
              >
              </ag-grid-vue>
            </b-col>
          </b-row>

          <b-row align-h="end">
            <b-col cols="12" md="4">
              <b-form-group style="text-align: end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="dark"
                  class="mr-1"
                  @click.prevent="validar"
                >
                  REALIZAR INVENTARIO INICIAL
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <b-overlay
        :show="submitted"
        no-wrap
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      />
      <ModalSelectArticulo
        ref="ModalSelectArticuloRef"
        esServicio="0"
        :ocultarCostoColumna="$hascan('venta-ver_costo')"
        @selectItemArticulo="selectItemArticulo"
      />
      <ModalPdfTransaccion
        :pdf="pdf"
        :pdfBase64="pdfBase64"
        :accionModalPdf="accionModalPdf"
        @cerrarModalPdf="cerrarModalPdf"
      />
    </b-card>
  </div>
</template>
<script>
import InventarioInicialServices from "../services/index";
import ProductoServices from "@/modules/administracion/producto/services/index";
import ModalPdfTransaccion from "@/modules/components/ModalPdfTransaccion.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ModalSelectArticulo from "@/modules/components/ModalSelectArticulo.vue";
import {
  BCard,
  BCardText,
  BButton,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BTabs,
  BTab,
  BCardGroup,
  BCardBody,
  BCardTitle,
  BCardFooter,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BFormSpinbutton,
  BFormRadio,
  BFormCheckbox,
  BTable,
  BInputGroup,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BBadge,
  BForm,
  BOverlay,
  BImg,
  BInputGroupAppend,
  VBTooltip,
  BInputGroupPrepend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import { required } from "@validations";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { AgGridVue } from "ag-grid-vue";
import moment from "moment";

export default {
  name: "NuevoInventarioIncialLayout",
  components: {
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardGroup,
    BCardBody,
    BCardTitle,
    BCardFooter,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    Cleave,
    BFormSpinbutton,
    BFormRadio,
    BFormCheckbox,
    BTable,
    VuePerfectScrollbar,
    BInputGroup,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BOverlay,
    BImg,
    BInputGroupAppend,
    VBTooltip,
    AgGridVue,
    BInputGroupPrepend,
    ModalSelectArticulo,
    ModalPdfTransaccion,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      // pdf
      accionModalPdf: false,
      pdf: "",
      pdfBase64: "",
      // modal articulo
      item: [],
      // table
      required,
      loading: false,

      // grid
      gridOptions: {
        localeText: {
          noRowsToShow: "",
          contains: "Contiene",
          notContains: "No Contiene",
          equals: "Es Igual",
          notEqual: "No es Igual",
          startsWith: "Comienza con",
          endsWith: "Termina con",
          filterOoo: "Filtral...",
        },
      },
      rowClassRules: null,
      gridApi: null,
      defaultColDef: {
        sortable: false,
        resizable: true,
        suppressMenu: false,
      },
      columnDefs: [
        {
          headerName: "CANTIDAD",
          field: "cantidad",
          type: "numericColumn",
          filter: false,
          valueFormatter: cantidadNumberFormatter,
          valueParser: cantidadParser,
          minWidth: 110,
          maxWidth: 110,
          editable: function (param) {
            if (!param.node.rowPinned) {
              return true;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
        },
        {
          headerName: "CODIGO",
          field: "codigo",
          filter: false,
          minWidth: 250,
          maxWidth: 250,
        },
        {
          headerName: "PRODUCTO",
          field: "descripcion",
          autoHeight: true,
          wrapText: true,
          filter: false,
          minWidth: 200,
        },
        {
          headerName: "LOTE",
          field: "lote",
          filter: false,
          minWidth: 110,
          maxWidth: 110,
          editable: function (param) {
            if (!param.node.rowPinned) {
              return true;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
        },
        {
          headerName: "VENCIMIENTO",
          field: "vencimiento",
          valueFormatter: function (params) {
            if (params.node.rowPinned) {
              return "";
            } else {
              if (params.value == "" || params.value == null) {
                params.data.vencimiento = null;
                return null;
              } else {
                const formattedDate = moment(
                  params.value,
                  "YYYY/MM/DD",
                  true
                ).format("YYYY/MM/DD");
                if (formattedDate === "Invalid date") {
                  params.data.vencimiento = null;
                  return null;
                } else {
                  return formattedDate;
                }
              }
            }
          },
          filter: false,
          minWidth: 110,
          maxWidth: 110,
          editable: function (param) {
            if (!param.node.rowPinned) {
              return true;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
        },
        {
          headerName: "",
          field: "info",
          type: "numericColumn",
          filter: false,
          minWidth: 150,
          maxWidth: 150,
        },
        {
          headerName: "COSTO",
          field: "costo",
          type: "numericColumn",
          filter: false,
          minWidth: 110,
          maxWidth: 110,
          valueFormatter: precioNumberFormatter,
          valueParser: costoParser,
          editable: function (param) {
            if (!param.node.rowPinned) {
              return true;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
        },
        {
          headerName: "SUBTOTAL",
          field: "subtotal",
          type: "numericColumn",
          filter: false,
          minWidth: 110,
          maxWidth: 110,
          valueFormatter: numberFormatter,
          editable: function (param) {
            if (param.node.rowPinned) {
              if (param.node.data.edit) {
                return true;
              }
              return false;
            } else {
              return false;
            }
          },
          singleClickEdit: true,
          valueGetter: (param) => {
            if (!param.node.rowPinned) {
              param.data.subtotal =
                param.data.cantidad * param.data.costo - param.data.descuento;
              return param.data.subtotal;
            }
            return param.node.data.subtotal;
          },
        },
        {
          headerName: "",
          filter: false,
          minWidth: 50,
          maxWidth: 50,
          cellRenderer: function (param) {
            if (param.node.rowPinned) {
              return undefined;
            } else {
              return `<button type="button" data-action-type="remove" class="btn btn-flat-danger btn-sm">X</button>`;
            }
          },
        },
      ],
      // end grid
      submitted: false,
      // transaccion
      transaccion: {
        cliente_proveedor_id: null,
        usuario_id: null,
        codigo_metodo_pago: null,
        metodo_pago: "",
        tipo_transaccion_id: 7,
        nro_tarjeta: null,
        subtotal: 0,
        descuento: 0,
        total: 0,
        razon_social: "",
        numero_documento: "",
        detalleTransaccion: [],
        comprobante: "",
        numero_comprobante: "",
        sede_id: "",
        efectivo: "0.00",
        tarjeta: "0.00",
        credito: "0.00",
        qr: "0.00",
        otro: "0.00",
        cambio: "0.00",
        transferencia_bancaria: "0.00",
      },
      // variables de clientes
      errores: [],
      codigo: "",
    };
  },
  created: function () {
    this.getRowStyle = (params) => {
      if (params.node.rowPinned) {
        if (params.node.data.bold) {
          return { "font-weight": "bold" };
        }
      }
    };
    window.addEventListener("keyup", this.onkey);
  },
  destroyed: function () {
    window.removeEventListener("keyup", this.onkey);
  },
  computed: {
    pinnedBottomRowData() {
      return [
        {
          info: "SUBTOTAL Bs",
          subtotal: this.calcularSubTotal(),
        },
        {
          info: "TOTAL Bs",
          subtotal: this.calcularTotal(),
          bold: true,
        },
      ];
    },
  },
  methods: {
    onkey(event) {
      if (event.key == "F9") {
        this.$refs.ModalSelectArticuloRef.focusBuscarArticulo();
      }
      if (event.key == "Escape") {
        document.getElementById("codigo").focus();
        document.getElementById("codigo").select();
      }
    },
    // VALIDAR FORMULARIO
    async validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.transaccion.detalleTransaccion.length > 0) {
            this.submitted = true;
            this.transaccion.sede_id = this.mixing.sede_id;
            this.registrar();
          } else {
            this.$bvToast.toast(" AGREGAR POR LO MENOS UN ARTICULO. ", {
              title: "ERROR!",
              variant: "danger",
              toaster: "b-toaster-top-center",
              solid: false,
            });
          }
        } else {
          this.$bvToast.toast(" COMPLETE LOS CAMPOS. ", {
            title: "ERROR!",
            variant: "danger",
            toaster: "b-toaster-top-center",
            solid: false,
          });
        }
      });
    },
    async registrar() {
      try {
        const response = await InventarioInicialServices.store(
          this.transaccion
        );
        this.errores = [];
        this.limpiarForm();
        setTimeout(() => {
          this.submitted = false;
          this.$bvToast.toast(
            "Inventario inicial se realizo con corretamente",
            {
              title: "EXITO!",
              variant: "success",
              solid: false,
            }
          );
          this.verPdf(response.data.id);
        }, 300);
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async verPdf(id) {
      try {
        const response = await InventarioInicialServices.findOnePdf(id);
        this.pdfBase64 = response.data.data;
        this.pdf =
          "data:application/pdf;base64," + response.data.data + "#toolbar=0";
        this.accionModalPdf = true;
      } catch (err) {
        console.log(err);
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    // remover de grid
    onRowClicked(e) {
      if (e.event.target !== undefined) {
        let rowIndex = e.node.id;
        let actionType = e.event.target.getAttribute("data-action-type");
        switch (actionType) {
          case "view":
          case "remove":
            return this.remover(rowIndex);
        }
      }
    },
    remover(index) {
      this.transaccion.detalleTransaccion.splice(index, 1);
    },
    // calculos de totales
    calcularSubTotal() {
      let resultado = 0;
      for (
        let index = 0;
        index < this.transaccion.detalleTransaccion.length;
        index++
      ) {
        resultado =
          resultado + this.transaccion.detalleTransaccion[index].subtotal;
      }
      resultado = resultado.toFixed(2);
      // this.transaccion.subtotal = parseFloat(resultado);
      this.transaccion.subtotal = resultado;
      return resultado;
    },
    calcularTotal() {
      let resultado = 0;
      resultado =
        parseFloat(this.transaccion.subtotal) -
        parseFloat(this.transaccion.descuento);
      resultado = resultado.toFixed(2);
      // this.transaccion.total = parseFloat(resultado);
      this.transaccion.total = resultado;
      return resultado;
    },

    onCellValueChanged(event) {
      if (event.data.key == "descuento") {
        this.transaccion.descuento = event.data.subtotal;
      }
    },
    onRowClicked(e) {
      if (e.event.target !== undefined) {
        let rowIndex = e.node.id;
        let actionType = e.event.target.getAttribute("data-action-type");
        switch (actionType) {
          case "view":
          case "remove":
            return this.remover(rowIndex);
        }
      }
    },
    // grid
    onGridReady(params) {
      params.api.sizeColumnsToFit();
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },

    // FORMATOS DE TEXTO EN MAUSCULAS Y DECIMALES
    textFormatter(value) {
      return value.toUpperCase();
    },
    numberFormatter(value) {
      return (value * 1).toFixed(2);
    },
    // limpiar formulario
    limpiarForm() {
      this.transaccion = {
        cliente_proveedor_id: null,
        codigo_metodo_pago: null,
        metodo_pago: "",
        tipo_transaccion_id: 1,
        nro_tarjeta: null,
        subtotal: 0,
        descuento: 0,
        total: 0,
        usuario_id: this.mixing.id,
        razon_social: "",
        numero_documento: "",
        detalleTransaccion: [],
        comprobante: "",
        numero_comprobante: "",
        sede_id: this.mixing.sede_id,
        efectivo: "0.00",
        tarjeta: "0.00",
        credito: "0.00",
        qr: "0.00",
        otro: "0.00",
        cambio: "0.00",
        transferencia_bancaria: "0.00",
      };
      this.codigo = "";
      this.$refs.ModalSelectArticuloRef.focusBuscarArticuloNuevo();
      this.$refs.simpleRules.reset();
    },

    // codigo de barra articulos
    async buscarCodigo() {
      if (this.codigo.length > 0) {
        try {
          const response = await ProductoServices.findOneCodigoNoStock(
            this.codigo,
            this.mixing.sede_id
          );
          this.selectItemArticulo(response.data, false);
        } catch (err) {
          console.log(err);
          this.$bvToast.toast(err.response.data.message, {
            title: "ADVERTENCIA!",
            variant: "warning",
            oaster: "b-toaster-top-center",
            solid: false,
          });
          document.getElementById("codigo").focus();
          document.getElementById("codigo").select();
        }
      }
    },
    // encuentra articulo
    encuentraArticulo(id, estado) {
      var sw = 0;
      for (let i = 0; i < this.transaccion.detalleTransaccion.length; i++) {
        if (this.transaccion.detalleTransaccion[i].id == id) {
          this.$bvToast.toast(" EL PRODUCTO YA FUE AGREGADO ", {
            title: "ADVERTENCIA  !",
            variant: "warning",
            toaster: "b-toaster-top-center",
            solid: false,
          });
          setTimeout(() => {
            this.focusAgridCantidad(i);
          }, 120);
          sw = true;
        }
      }
      return sw;
    },
    // Emit de select articulo
    selectItemArticulo(producto, estado) {
      if (producto) {
        setTimeout(() => {
          this.agregarItem(producto, estado);
        }, 100);
      } else {
        this.$bvToast.toast(" POR FAVOR SELECCIONE UN ARTICULO ", {
          title: "ADVERTENCIA  !",
          variant: "warning",
          toaster: "b-toaster-top-center",
          solid: false,
        });
      }
    },
    agregarItem(producto, estado) {
      if (!this.encuentraArticulo(producto.id, estado)) {
        const detalle = this.pedido?.transaccion.detalle_transaccion.find(
          (detalle) => {
            if (detalle.producto.id == producto.id) {
              return detalle;
            }
          }
        );

        const cantidad = this.pedido ? detalle.cantidad : 1;

        const costo = this.pedido ? detalle.costo : producto.costo;

        const subtotal = costo * cantidad;

        this.transaccion.detalleTransaccion.push({
          id: producto.id,
          cantidad: cantidad,
          codigo: producto.codigo,
          descripcion: producto.descripcion,
          descuento: 0,
          costo: costo,
          lote: "",
          vencimiento: "",
          precio: 0,
          subtotal: subtotal,
          unidad: producto.unidad,
        });
        if (estado) {
          setTimeout(() => {
            this.focusAgrid();
          }, 120);
        } else {
          setTimeout(() => {
            document.getElementById("codigo").focus();
            document.getElementById("codigo").select();
          }, 120);
          this.gridApi.redrawRows();
        }
      }
    },
    focusAgrid() {
      const cellrows = this.gridApi.getDisplayedRowCount();
      this.gridApi.setFocusedCell(cellrows - 1, "cantidad");
      this.gridApi.startEditingCell({
        rowIndex: cellrows - 1,
        colKey: "cantidad",
      });
    },
    focusAgridCantidad(i) {
      this.gridApi.setFocusedCell(i, "cantidad");
      this.gridApi.startEditingCell({
        rowIndex: i,
        colKey: "cantidad",
      });
    },
    // key pres
    onCellKeyDown(params) {
      if (params.event) {
        if (params.event.key == "Enter") {
          setTimeout(() => {
            document.getElementById("codigo").focus();
          }, 200);
        }
      }
    },

    cerrarModalPdf() {
      this.accionModalPdf = false;
      this.pdf = "";
      this.pdfBase64 = "";
      this.$emit("compra-registrada");
    },

    async buscarProductosPedidosPorIds(ids) {
      if (ids.length > 0) {
        try {
          const response = await ProductoServices.findByIds(
            ids,
            this.mixing.sede_id
          );
          const productos = response.data;

          productos.forEach((producto) => {
            this.selectItemArticulo(producto, false);
          });
        } catch (err) {
          console.log(err);
          this.$bvToast.toast(err.response.data.message, {
            title: "ADVERTENCIA!",
            variant: "warning",
            oaster: "b-toaster-top-center",
            solid: false,
          });
          document.getElementById("codigo").focus();
          document.getElementById("codigo").select();
        }
      }
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.$refs.ModalSelectArticuloRef.focusBuscarArticuloNuevo();
    this.transaccion.usuario_id = this.mixing.id;
  },
};
function numberFormatter(params) {
  return Number(params.value).toFixed(2);
}
function cantidadNumberFormatter(params) {
  if (params.node.rowPinned) {
    return params.value;
  } else {
    return Number(params.value).toFixed(2);
  }
}
function precioNumberFormatter(params) {
  if (params.node.rowPinned) {
    return params.value;
  } else {
    return Number(params.value).toFixed(2);
  }
}
function cantidadParser(params) {
  if (!isNaN(params.newValue) && params.newValue >= 0) {
    return Number(params.newValue).toFixed(2);
  } else {
    return params.data.cantidad;
  }
}
function costoParser(params) {
  if (!isNaN(params.newValue) && params.newValue >= 0) {
    return Number(params.newValue).toFixed(2);
  } else {
    return params.data.costo;
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/components/agGridStyle.scss";
.demo-inline-spacing > * {
  margin-right: 0.5rem;
  margin-top: 0rem;
}
.table-active > td {
  background-color: #ff7676 !important;
  color: white;
}
.table-active > td > button {
  background-color: #ff7676 !important;
  color: white;
}
.b-table-sticky-header {
  overflow-y: auto;
  max-height: calc(var(--vh, 1vh) * 100 - 26.3rem);
}
.my-0-5 {
  margin: 0.5rem !important;
  margin-left: 0px !important;
}
#card-pv {
  .card-body {
    padding: 0.8rem;
  }
}
.hr {
  margin-top: 0.1rem;
  margin-bottom: 0.25rem;
}
[data-popper-placement="top"] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
.box {
  height: 9rem;
}
.box img {
  width: 100%;
  height: 100%;
}
.box1 img {
  object-fit: cover;
  // object-fit: contain;
}
.custom-select:disabled {
  color: #6e6b7b;
  font-weight: 500;
}

.btnBuscarArticulo {
  margin-top: 2rem;
}

@media (min-width: 992px) {
  .btnBuscarArticulo {
    margin-top: 0;
  }
}
</style>