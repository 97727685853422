<template>
  <b-modal
    id="modal-from-categoria"
    v-model="modalCategoria"
    ref="modal"
    :title="tituloModal"
    no-close-on-backdrop
    :ok-disabled="submitted"
    :cancel-disabled="submitted"
    no-close-on-esc
    ok-title="Guardar"
    ok-variant="primary"
    cancel-variant="outline-secondary"
    cancel-title="Cerrar"
    button-size="sm"
    @cancel.prevent="cerrarModal"
    @close.prevent="cerrarModal"
    @ok.prevent="validar"
  >
    <b-overlay
      :show="submitted"
      no-wrap
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    />
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col class="mb-0-5" cols="12">
            <b-row>
              <b-col class="mb-0-5 mt-2" cols="12">
                <b-form-group
                  label-cols="12"
                  label-cols-lg="4"
                  label="* DESCRIPCIÓN "
                  label-for="descripcionCategoria"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Descripcion"
                    rules="required|min:2"
                  >
                    <b-form-input
                      id="descripcionCategoria"
                      v-model="form.descripcion"
                      @keyup.enter="validar()"
                      lazy-formatter
                      :formatter="textFormatter"
                      :state="errors.length > 0 ? false : null"
                      size="sm"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-for="error in errores.descripcion"
                      :key="error"
                      class="text-danger"
                      >{{ error }}</small
                    >
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col class="mb-0-5" cols="12">
                <validation-provider
                  #default="{ errors }"
                  name="Categoria padre"
                >
                  <b-form-group
                    label="* CATEGORIA PADRE"
                    label-for="categoriaPadre"
                    label-cols="12"
                    label-cols-lg="4"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="categoriaPadre"
                      v-model.number="form.categoria_padre_id"
                      class="style-chooser select-size-sm"
                      label="descripcion"
                      :options="categoriaItems"
                      :key="form.categoria_padre_id"
                      :reduce="(op) => op.id"
                      :class="{ 'is-invalid': !!errors.length }"
                    />
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    <small
                      v-for="error in errores.categoria_padre_id"
                      :key="error"
                      class="text-danger"
                      >{{ error }}</small
                    >
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="DESCRIPCIÓN CORTA"
                  label-for="descripcion_corta"
                  label-cols="12"
                  label-cols-lg="4"
                >
                  <b-form-textarea
                    id="descripcion_corta"
                    v-model="form.descripcion_corta"
                    size="sm"
                    lazy-formatter
                    rows="5"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="mb-0-5" cols="12">
            <b-col class="mb-0-5 mt-1" cols="12" md="8" offset-md="4">
              <div class="contenedor-imagen-categoria-marca boxs1">
                <b-img
                  v-if="vistaPreviaImagen"
                  :src="vistaPreviaImagen"
                  center
                  thumbnail
                  fluid
                  class="rounded"
                  alt="Sin imagen"
                />
                <b-img
                  v-else
                  v-bind="imgAtributos"
                  rounded
                  alt="Rounded image"
                  class="d-inline-block mr-1 mb-1 border"
                />
              </div>

              <b-form-group class="mt-1" label="IMAGEN" label-for="imagen">
                <validation-provider
                  #default="{ errors }"
                  name="imagen"
                  rules=""
                >
                  <b-form-file
                    @change="onFileChanged"
                    v-model="form.imagen"
                    accept="image/*"
                    placeholder="Elija una imagen1"
                    drop-placeholder="Ningun imagen seleccionado"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-for="error in errores.imagen"
                    :key="error"
                    class="text-danger"
                    >{{ error }}</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-col>
          <b-col cols="12" class="mb-0-5">
            <b-form-group
              label="DESCRIPCIÓN LARGA"
              label-for="descripcion_larga"
            >
              <quill-editor v-model="form.descripcion_larga" :options="options">
              </quill-editor>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import CategoriaServices from "../services/index";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BModal,
  BButton,
  VBModal,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BOverlay,
  BFormSelect,
  BImg,
  BFormFile,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import vSelect from "vue-select";
import formImagenMixin from "../../mixins/form-imagen-mixin";

import { quillEditor } from "vue-quill-editor";

export default {
  name: "ModalFormCategoria",
  components: {
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BOverlay,
    BFormSelect,
    vSelect,
    BImg,
    BFormFile,
    BFormTextarea,
    quillEditor,
  },
  mixins: [formImagenMixin],
  props: ["item"],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      options: {},
      form: {
        id: null,
        descripcion: "",
        descripcion_corta: "",
        descripcion_larga: "",
        categoria_padre_id: null,
        imagen: null,
      },
      categoriaItems: [],
      required,
      submitted: false,
      errores: [],
      modalCategoria: false,
      estadoItems: [
        { text: "ACTIVO", value: 1 },
        { text: "INACTIVO", value: 0 },
      ],
    };
  },
  computed: {
    tituloModal() {
      return this.form.id ? "EDITAR CATEGORIA" : "NUEVA CATEGORIA";
    },
  },
  watch: {
    "form.descripcion": function () {
      this.errores.descripcion = [];
    },
    "form.categoria_padre_id": function () {
      this.errores.categoria_padre_id = [];
    },
    "form.imagen": function () {
      this.errores.imagen = [];
    },
  },
  methods: {
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            this.submitted = true;
            if (this.form.id) {
              this.actualizar();
            } else {
              this.registrar();
            }
          }
        }
      });
    },
    async abrir(accion, data = []) {
      switch (accion) {
        case "registrar": {
          this.form.id = null;
          this.form.descripcion = "";
          this.form.descripcion_corta = "";
          this.form.descripcion_larga = "";
          this.form.categoria_padre_id = null;
          this.form.imagen = null;
          this.vistaPreviaImagen = null;
          break;
        }
        case "actualizar": {
          await this.getCategoria(data["id"]);
          break;
        }
      }
      this.modalCategoria = true;
      this.getCategorias();
      this.errores = [];
    },
    async getCategoria(id) {
      await CategoriaServices.getOne(id)
        .then((response) => {
          const data = response.data;
          this.form.id = data.id;
          this.form.descripcion = data.descripcion;
          this.form.descripcion_corta = data.descripcion_corta;
          this.form.descripcion_larga = data.descripcion_larga;
          this.form.categoria_padre_id = data.categoria_padre_id;
          this.form.imagen = null;
          this.vistaPreviaImagen = data.imagen ? data.imagen : null;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cerrarModal() {
      this.form.id = null;
      this.form.categoria_padre_id = null;
      this.modalCategoria = false;
    },
    actualizarListado(item) {
      this.form.id = null;
      this.form.categoria_padre_id = null;
      this.modalCategoria = false;
      this.$emit("actualizarListado", item);
      this.submitted = false;
    },
    // SERVICES
    async registrar() {
      const formData = this.generarFormData();
      try {
        const response = await CategoriaServices.store(formData);

        this.actualizarListado(response.data);
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    async actualizar() {
      const formData = this.generarFormData();
      try {
        const response = await CategoriaServices.update(this.form.id, formData);
        this.actualizarListado(response.data);
        this.errores = [];
      } catch (err) {
        this.submitted = false;
        this.errores = err.response.data.errors;
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    textFormatter(value) {
      return value.toUpperCase();
    },
    numberFormatter(value) {
      return (value * 1).toFixed(2);
    },
    async getCategorias() {
      try {
        const response = await CategoriaServices.get();
        this.categoriaItems = response.data;
      } catch (err) {
        this.$bvToast.toast(err.response.data.message, {
          title: "ERROR!",
          variant: "danger",
          solid: false,
        });
      }
    },
    generarFormData() {
      const formData = new FormData();
      formData.append("descripcion", this.form.descripcion);
      formData.append("descripcion_corta", this.form.descripcion_corta);
      formData.append("descripcion_larga", this.form.descripcion_larga);

      if (this.form.categoria_padre_id) {
        formData.append("categoria_padre_id", this.form.categoria_padre_id);
      }

      if (this.form.imagen) {
        formData.append("file", this.form.imagen);
      }

      return formData;
    },
  },
  mounted() {
    this.options = {
      modules: {
        toolbar: [
          ["bold", "italic"],
          [{ size: ["small", false, "large", "huge"] }],
          [{ font: [] }],
        ],
      },
      theme: "snow",
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
.contenedor-imagen-categoria-marca {
  height: 14rem;
  background: #fff;
}

.contenedor-imagen-categoria-marca img {
  width: 100%;
  height: 100%;
}
.boxs img {
  object-fit: contain;
}
</style>