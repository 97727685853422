import { render, staticRenderFns } from "./ModalFormCategoria.vue?vue&type=template&id=bf688842&"
import script from "./ModalFormCategoria.vue?vue&type=script&lang=js&"
export * from "./ModalFormCategoria.vue?vue&type=script&lang=js&"
import style0 from "./ModalFormCategoria.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports