import axiosIns from "@/libs/axios";

const get = async ({ page, limit, searchQuery }) => {
  return await axiosIns.get(`ubicaciones`)
}

const store = async (data) => {
  return await axiosIns.post('ubicaciones', data)
}

const update = async (id, data) => {
  return await axiosIns.patch(`ubicaciones/${id}`, data)
}

const destroy = async (id) => {
    return await axiosIns.delete(`ubicaciones/${id}`)
}
  
export default {
  get,
  store,
  update,
  destroy
}
