import axiosIns from "@/libs/axios";

const get = async () => {
  return await axiosIns.get(`marcas`)
}

const store = async (data) => {
  return await axiosIns.post('marcas', data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

const update = async (id, data) => {
  return await axiosIns.post(`marcas/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

const getOne = async (id) => {
  return await axiosIns.get(`marcas/${id}`);
};

const destroy = async (id) => {
  return await axiosIns.delete(`marcas/${id}`)
}
  
export default {
  get,
  store,
  update,
  destroy,
  getOne
}
