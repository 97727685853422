import axiosIns from "@/libs/axios";

const get = async () => {
  return await axiosIns.get(`categorias`);
};

const store = async (data) => {
  return await axiosIns.post("categorias", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const update = async (id, data) => {
  return await axiosIns.post(`categorias/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const destroy = async (id) => {
  return await axiosIns.delete(`categorias/${id}`);
};

const getOne = async (id) => {
  return await axiosIns.get(`categorias/${id}`);
};

export default {
  get,
  store,
  update,
  destroy,
  getOne
};
